import loadable from "@loadable/component";

// Redux and Connected Router:
import { Provider } from "react-redux";
import { store } from "../reducers/configureStore";
import {
  Switch,
  Router,
  BrowserRouter,
  Route,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";

// Navigation:
import { Navigation, Footer } from "./navigation";
import ScrollMemory from "react-router-scroll-memory";

import { PageLoader } from "./components/PageLoader";

const history = createBrowserHistory();
// Lazy loading Pages:
const Home = loadable(() => import("./pages/home/Home"), {
  fallback: <PageLoader />,
});
const ManagedCloud = loadable(
  () => import("./pages/solutions/managed_cloud/ManagedCloud"),
  {
    fallback: <PageLoader />,
  }
);

/**
 * Main App frame.
 */
const App = () => {
  return (
    <BrowserRouter>
      <ScrollMemory />

      <Provider store={store}>
        <Router history={history}>
          <div className="g-content-wrapper">
            {/*  Navigation Bar */}
            <Navigation />

            {/* Pages */}

            <Switch>
              {/* Home */}
              <Route exact path="/" component={Home} />
              <Route path="/#solutions" component={Home} />
              <Route path="/#benefits" component={Home} />

              {/* Redirections */}
              <Route exact path="/solutions">
                <Redirect to="/#solutions" />
              </Route>
              <Route exact path="/benefits">
                <Redirect to="/#benefits" />
              </Route>

              {/* Solutions pages */}
              <Route path="/solutions/managed-cloud" component={ManagedCloud} />
            </Switch>

            <Footer />
          </div>
        </Router>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
