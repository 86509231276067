/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

export const useNavigationControllers = () => {
  const [isOnTop, setOnTop] = useState(false);

  const [offset, setOffset] = useState(0);
  const [prevY, setPrevy] = useState(0);
  const [currY, setCurrY] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /*
   * Hide Navagtion when scroll up
   * and down on mobile devices.
   */
  useEffect(() => {
    const Y = currY;

    if (Y > 20) {
      const diff = prevY - Y;

      const o = offset + diff;
      if (o >= -80 && o <= 0) {
        setOffset(o);
      }

      setPrevy(Y);
    }
  }, [currY]);

  const handleScroll = () => {
    const Y = window.pageYOffset;
    setCurrY(Y);

    if (Y > 20) {
      setOnTop(false);
    } else {
      setOffset(0);
      setOnTop(true);
    }
  };

  return { isOnTop, topOffset: offset };
};
