export const CodecsIcon = ({ style }) => (
  <svg
    width="92"
    height="57"
    viewBox="0 0 95 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M68 21.0999C67.8 21.0999 67.5 20.9999 67.4 20.7999C67.2 20.4999 67.2 19.9999 67.5 19.6999L93.5 0.199938C93.8 -0.100062 94.3 -6.23465e-05 94.6 0.299938C94.8 0.599938 94.8 1.09994 94.5 1.39994L68.5 20.9999C68.3 21.0999 68.2 21.0999 68 21.0999Z"
      fill="#4FAABB"
    />
    <path
      d="M63.7 15.6998C63.6 15.6998 63.6 15.6998 63.5 15.6998C63.1 15.5998 62.9 15.1998 63 14.7998L66.7 1.99985C66.8 1.59985 67.2 1.39985 67.6 1.49985C68 1.59985 68.2 1.99985 68.1 2.39985L64.4 15.1998C64.4 15.3998 64.1 15.6998 63.7 15.6998Z"
      fill="#4FAABB"
    />
    <path
      d="M70 27.9C69.6 27.9 69.3 27.6001 69.2 27.2001C69.2 26.8001 69.5 26.4 69.9 26.4L83.2 26.3C83.6 26.3 83.9 26.6 84 27C84 27.4 83.7 27.8 83.3 27.8L70 27.9Z"
      fill="#4FAABB"
    />
    <path
      d="M31.3 59.5999C23.1 59.5999 14.8 59.0999 6.59999 58.1999C4.59999 57.9999 2.49999 56.1999 1.99999 54.1999C-0.600012 44.2999 -0.600012 34.1999 1.99999 24.1999C2.49999 22.2999 4.59999 20.4999 6.59999 20.1999C23 18.2999 39.6 18.2999 56 20.1999C58 20.3999 60.1 22.1999 60.6 24.1999C63.2 34.0999 63.2 44.1999 60.6 54.1999C60.1 56.0999 58 57.8999 56 58.1999C47.8 59.0999 39.5 59.5999 31.3 59.5999ZM31.3 20.8999C23.1 20.8999 15 21.3999 6.79999 22.2999C5.59999 22.3999 4.19999 23.5999 3.89999 24.7999C1.39999 34.3999 1.39999 44.0999 3.89999 53.6999C4.19999 54.8999 5.59999 55.9999 6.79999 56.1999C23 58.0999 39.5 58.0999 55.7 56.1999C56.9 56.0999 58.3 54.8999 58.6 53.6999C61.1 44.0999 61.1 34.3999 58.6 24.7999C58.3 23.5999 56.9 22.4999 55.7 22.2999C47.6 21.3999 39.5 20.8999 31.3 20.8999Z"
      fill="#432E7F"
    />
    <path
      d="M6.39996 20.4C6.49996 20.1 6.49996 19.7 6.59996 19.4C6.79996 18.1 8.19996 16.9 9.49996 16.8C16.7 16.2 24 15.9 31.3 15.9C38.6 15.9 45.9 16.2 53.1 16.8C54.4 16.9 55.8 18.1 56 19.4C56.1 19.7 56.1 20.1 56.2 20.4C56.8 20.5 57.3 20.7 57.8 21C57.7 20.4 57.6 19.7 57.5 19.1C57.1 17.2 55.2 15.4 53.3 15.3C38.7 14 23.9 14 9.39996 15.3C7.39996 15.5 5.49996 17.2 5.19996 19.1C5.09996 19.7 4.99996 20.4 4.89996 21C5.29996 20.7 5.79996 20.4 6.39996 20.4Z"
      fill="#4FAABB"
    />
    <path
      d="M11.1 15.0999C11.1 14.6999 11.2 14.3999 11.2 13.9999C11.4 12.6999 12.6 11.5999 13.9 11.4999C19.7 11.1999 25.5 10.9999 31.3 10.9999C37.1 10.9999 42.9 11.1999 48.7 11.4999C50 11.5999 51.2 12.6999 51.4 13.9999C51.4 14.3999 51.5 14.6999 51.5 15.0999C52 15.0999 52.5 15.1999 53 15.1999C53 14.6999 52.9 14.2999 52.9 13.7999C52.7 11.7999 50.8 10.0999 48.8 9.99985C37.2 9.29985 25.4 9.29985 13.8 9.99985C11.8 10.0999 9.99998 11.7999 9.69998 13.7999C9.59998 14.2999 9.59998 14.6999 9.59998 15.1999C10.1 15.0999 10.6 15.0999 11.1 15.0999Z"
      fill="#432E7F"
    />
    <path
      d="M27.4 47.9999C27.3 47.9999 27.2 47.9999 27 47.8999C26.8 47.7999 26.6 47.4999 26.6 47.1999V31.7999C26.6 31.4999 26.8 31.2999 27 31.0999C27.2 30.9999 27.5 30.9999 27.8 31.0999L39.2 38.7999C39.4 38.8999 39.5 39.1999 39.5 39.3999C39.5 39.5999 39.4 39.8999 39.2 39.9999L27.8 47.6999C27.7 47.8999 27.5 47.9999 27.4 47.9999ZM28.2 33.1999V45.7999L37.5 39.4999L28.2 33.1999Z"
      fill="#4FAABB"
    />
  </svg>
);
