export const ValueIcon = ({ style }) => (
  <svg
    width="39"
    height="32"
    viewBox="0 0 69 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.3 58.2999C25.2 58.2999 25.2 58.2999 25.1 58.2999C17 56.4999 10.2 51.6999 5.69998 44.6999C1.29998 37.6999 -0.200024 29.3999 1.69998 21.3999C1.79998 20.9999 2.19998 20.6999 2.59998 20.7999C2.99998 20.8999 3.29998 21.2999 3.19998 21.6999C-0.400024 37.4999 9.59998 53.2999 25.5 56.8999C25.9 56.9999 26.2 57.3999 26.1 57.7999C26 57.9999 25.7 58.2999 25.3 58.2999Z"
      fill="#4FAABB"
    />
    <path
      d="M31.9 52.9C30.1 52.9 28.3 52.7 26.5 52.3C13.2 49.3 4.69997 36 7.69997 22.6C9.99997 12.5 18.5 4.80005 28.8 3.50005C29.5 3.40005 30.1 3.90005 30.2 4.60005C30.3 5.30005 29.8 5.90005 29.1 6.00005C19.8 7.20005 12.2 14.1 10.2 23.2C7.49997 35.2 15.1 47.2 27.1 49.8C37.3 52.1 47.9 46.8 52.3 37.3C52.6 36.7 53.3 36.4 54 36.7C54.6 37 54.9 37.7 54.6 38.4C50.4 47.3 41.4 52.9 31.9 52.9Z"
      fill="#432E7F"
    />
    <path
      d="M66.7 1.8C66.7 1.6 66.7 1.4 66.6 1.3V1.1C66.5 0.4 65.8 0 65.2 0.1C64.9 0 64.6 0 64.4 0H55.3C54.6 0 54 0.6 54 1.2C54 1.8 54.6 2.4 55.2 2.4H63.4L41 26.8C40.8 27 40.6 27 40.5 27C40.4 27 40.2 27 40.1 26.8L34.4 20.6C33.8 20 33 19.6 32.1 19.6C31.2 19.6 30.4 20 29.8 20.6L19.7 32C19.2 32.5 19.3 33.3 19.8 33.8C20 34 20.3 34.1 20.6 34.1C20.9 34.1 21.3 34 21.5 33.7L31.6 22.4C31.8 22.2 31.9 22.2 32 22.2C32.2 22.2 32.3 22.2 32.4 22.4L38.1 28.6C38.7 29.2 39.5 29.6 40.4 29.6C41.3 29.6 42.1 29.2 42.7 28.6L64.7 4.9L66.1 12.8C66.2 13.5 66.9 13.9 67.5 13.8C68.2 13.7 68.6 13 68.5 12.4L66.7 1.8Z"
      fill="#432E7F"
    />
  </svg>
);
