/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAppState } from "../../reducers/ui/app/selectors";
import { useMediaQuery } from "react-responsive";
import { useDispatch } from "react-redux";

import { get } from "../../localization";
import { setSolutionsVisibility } from "../../reducers/ui/app/reducer";

import { Space, Dropdown } from "antd";
import { SolutionsSubmenu } from "./SolutionsSubmenu";
import { MenuHashLink, MenuItem } from "./Navbar";
import { BlogLink } from "./BlogLink";

export const DesktopBar = () => {
  const shouldHideVideo = useMediaQuery({
    query: "(max-device-width: 1200px)",
  });

  const dispatch = useDispatch();
  const { solutionsVisible } = useAppState();

  return (
    <Space size={40}>
      <MenuHashLink to="/#benefits">
        <MenuItem>{get("nav-benefit")}</MenuItem>
      </MenuHashLink>

      <Dropdown
        visible={solutionsVisible}
        onVisibleChange={(visible) => {
          dispatch(setSolutionsVisibility(visible));
        }}
        overlay={() => <SolutionsSubmenu hideVideo={shouldHideVideo} />}
        placement="bottomCenter"
        trigger="click"
      >
        <a>
          <MenuItem>{get("nav-solutions")}</MenuItem>
        </a>
      </Dropdown>

      <BlogLink>
        <MenuItem>Blog</MenuItem>
      </BlogLink>

      <MenuItem className="g-menu-button">{get("early-access")}</MenuItem>
    </Space>
  );
};
