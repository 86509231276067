import React from "react";
export const CloudParallel = ({ color = "#394461", style }) => (
  <svg
    width="29"
    height="25"
    style={style}
    viewBox="0 0 31 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M9 22.8H13"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7 26H18.5"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4 22.8H24"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.2 19.4H23C26.7 19.4 29.7 16.4 29.7 12.7C29.7 9 26.6 6 22.9 6C22.6 6 22.3 6 22 6.1C21 3.1 18.2 1 14.8 1C10.8 1 7.4 4.1 7.1 8.1C6.9 8.1 6.8 8.1 6.6 8.1C3.5 8.1 1 10.6 1 13.7C1 16.8 3.5 19.4 6.7 19.4H10.3"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 19.3999H15.8"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4 10.5C17.4 8.00005 19.8 6.30005 22.6 6.30005"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="30.6" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
