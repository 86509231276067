import { createSlice } from "@reduxjs/toolkit";

// Constants ////////// //////////////////////
export const getAppState = (state) => state.ui.app;

export const initialState = {
  solutionsVisible: false,
  menuVisible: false,
};

/**
 * Redux Slicer for Main App.
 */
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setSolutionsVisibility(state, action) {
      state.solutionsVisible = action.payload;
    },

    showSolutionsSubmenu(state) {
      state.solutionsVisible = true;
    },

    hideSolutionsSubmenu(state) {
      state.solutionsVisible = false;
    },

    //////

    setMenuVisibility(state, action) {
      state.menuVisible = action.payload;
    },

    showMenu(state) {
      state.menuVisible = true;
    },

    hideMenu(state) {
      state.menuVisible = false;
    },

    toggleMenu(state) {
      state.menuVisible = !state.menuVisible;
    },
  },
});

export const {
  setSolutionsVisibility,
  showSolutionsSubmenu,
  hideSolutionsSubmenu,
  setMenuVisibility,
  showMenu,
  toggleMenu,
  hideMenu,
} = appSlice.actions;

export default appSlice.reducer;
