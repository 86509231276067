import { useMediaQuery } from "react-responsive";

import styled from "styled-components";
import { Navbar } from "./Navbar";
import { useNavigationControllers } from "./Hooks";

export const Navigation = () => {
  /*
   * Control navigation show and size.
   */
  const { isOnTop, topOffset } = useNavigationControllers();

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  return (
    <NavInner
      isOnTop={isOnTop}
      isDesktop={isDesktop}
      margin={isDesktop ? 10 : 4}
      top={!isDesktop ? topOffset : 0}
    >
      <Navbar />
    </NavInner>
  );
};

const NavInner = styled.div`
  width: calc(100% - 20px);
  width: ${(props) => `calc(100% - ${props.margin * 2}px)`};

  max-width: 1330px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  background: var(--gencode-red);

  color: white;
  z-index: 5;

  transition: ${(props) => (props.isDesktop ? "box-shadow 0.6s" : "all 0.6s")};

  border-radius: ${(props) => (props.isOnTop ? "5px" : "0px 0px 5px 5px")};

  padding: ${(props) =>
    props.isDesktop ? "20px 30px 20px 50px;" : "20px 20px 20px 25px"};

  padding-top: ${(props) => (props.isOnTop ? "20px " : "30px ")};

  margin: ${(props) => `0px ${props.margin}px 0px ${props.margin}px`};

  margin-top: ${(props) => (props.isOnTop ? "10px" : "0px")};
  top: ${(props) => `${props.top}px`};

  box-shadow: ${(props) =>
    !props.isOnTop && "10px 10px 26px 2px rgba(0, 0, 0, 0.13)"};
`;
