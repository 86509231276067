import styled from "styled-components";

import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const PageLoader = () => (
  <PageLoaderInner>
    <Spin size="large" indicator={<LoadingOutlined spin />} />
  </PageLoaderInner>
);

const PageLoaderInner = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  margin-top: 120px;
`;
