export const OutputsIcon = ({ height = 50, style }) => (
  <svg
    width={Math.abs(height * 1.99)}
    height={height}
    viewBox="0 0 121 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M52.1 50.7C51.8 53.9 48.7 57.1 45.5 57.3C42.3 57.5 39.2 57.5 36 57.3C32.8 57 29.6 53.9 29.4 50.7C29.2 47.5 29.2 44.4 29.4 41.2C29.7 38 32.8 34.8 36 34.6C39.2 34.4 42.3 34.4 45.5 34.6C48.7 34.9 51.9 38 52.1 41.2C52.3 44.3 52.3 47.5 52.1 50.7Z"
      stroke="#18244D"
      strokeWidth="3.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6 34.7999C19.8 35.0999 23 38.1999 23.2 41.3999C23.4 44.5999 23.4 47.6999 23.2 50.8999C22.9 54.0999 19.8 57.2999 16.6 57.4999C13.4 57.6999 10.3 57.6999 7.09998 57.4999C3.89998 57.1999 0.699976 54.0999 0.499976 50.8999C0.299976 47.6999 0.299976 44.5999 0.499976 41.3999C0.799976 38.1999 3.89998 34.9999 7.09998 34.7999C10.3 34.5999 13.5 34.5999 16.6 34.7999Z"
      fill="url(#outputs_icon_0)"
    />
    <path
      d="M88.2 18.1001C87.9 21.3001 84.8 24.5001 81.6 24.7001C78.4 24.9001 75.3 24.9001 72.1 24.7001C68.9 24.4001 65.7 21.3001 65.5 18.1001C65.3 14.9001 65.3 11.8001 65.5 8.6001C65.8 5.4001 68.9 2.2001 72.1 2.0001C75.3 1.8001 78.4 1.8001 81.6 2.0001C84.8 2.3001 88 5.4001 88.2 8.6001C88.4 11.7001 88.4 14.9001 88.2 18.1001Z"
      stroke="#18244D"
      strokeWidth="3.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M118.2 18.1001C117.9 21.3001 114.8 24.5001 111.6 24.7001C108.4 24.9001 105.3 24.9001 102.1 24.7001C98.9 24.4001 95.7 21.3001 95.5 18.1001C95.3 14.9001 95.3 11.8001 95.5 8.6001C95.8 5.4001 98.9 2.2001 102.1 2.0001C105.3 1.8001 108.4 1.8001 111.6 2.0001C114.8 2.3001 118 5.4001 118.2 8.6001C118.4 11.7001 118.4 14.9001 118.2 18.1001Z"
      stroke="#18244D"
      strokeWidth="3.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.4 6.7C58.1 3.4 54.9 0.6 51.6 0.5C36.8 0 22 0 7.1 0.5C3.9 0.6 0.6 3.4 0.3 6.7C-0.1 11.2 -0.1 15.7 0.3 20.2C0.6 23.5 3.8 26.3 7.1 26.4C21.9 26.8 36.8 26.8 51.6 26.4C54.9 26.3 58.1 23.5 58.4 20.2C58.9 15.7 58.9 11.2 58.4 6.7ZM25.9 16.4C25.9 16.9 25.6 17.2 25.2 17.2H24.9C24.4 17.2 24.2 16.9 24.2 16.4V14H20.9V16.4C20.9 16.9 20.6 17.2 20.1 17.2H19.9C19.4 17.2 19.2 16.9 19.2 16.4V10.2C19.2 9.7 19.5 9.4 19.9 9.4H20.1C20.6 9.4 20.9 9.7 20.9 10.2V12.6H24.2V10.2C24.2 9.7 24.4 9.4 24.9 9.4H25.2C25.7 9.4 25.9 9.7 25.9 10.2V16.4ZM32.9 16.4C32.9 16.9 32.6 17.2 32.1 17.2H28.4C27.8 17.2 27.5 16.9 27.5 16.2V10.2C27.5 9.7 27.8 9.4 28.2 9.4H28.4C28.9 9.4 29.1 9.7 29.1 10.2V15.6H31.9C32.6 15.6 32.9 15.8 32.9 16.4ZM36.6 17.3C35.4 17.3 34.6 17 34.2 16.6C33.9 16.3 33.7 16 33.7 15.7C33.7 15.2 34.3 14.8 34.4 14.8C34.9 15.3 35.7 15.8 36.6 15.8C37.5 15.8 38 15.5 38 14.9C38 14.2 37.2 14.1 36.3 13.9C35.1 13.6 33.9 13.2 33.9 11.6C33.9 10.2 35 9.2 36.8 9.2C37.8 9.2 38.5 9.5 39 9.8C39.4 10.1 39.5 10.4 39.5 10.6C39.5 11.1 38.9 11.6 38.8 11.5C38.4 11 37.6 10.6 36.8 10.6C36 10.6 35.5 10.9 35.5 11.5C35.5 12.2 36.2 12.3 37.1 12.5C38.5 12.8 39.7 13.4 39.7 14.8C39.7 16.4 38.6 17.3 36.6 17.3Z"
      fill="url(#outsputs_icon_1_linear)"
    />
    <path
      d="M78.5 46.8C78.5 48.3 77.6 49.2 76.1 49.2H75.1V44.5H76.2C77.6 44.5 78.5 45.3 78.5 46.8ZM83.4 47.4H85.5L84.5 44.6L83.4 47.4ZM118.4 54.5C118.1 57.8 114.9 60.6 111.6 60.7C96 61.1 80.4 61.1 64.8 60.7C61.5 60.6 58.3 57.8 58 54.5C57.5 49.7 57.5 44.8 58 40C58.3 36.7 61.6 33.9 64.8 33.8C80.4 33.4 96 33.4 111.6 33.8C114.9 33.9 118.1 36.7 118.4 40C118.8 44.8 118.8 49.6 118.4 54.5ZM80.2 46.8C80.2 44.4 78.7 43 76.1 43H74.2C73.7 43 73.3 43.4 73.3 44V49.8C73.3 50.3 73.7 50.8 74.3 50.8H76.1C78.6 50.7 80.2 49.2 80.2 46.8ZM88.2 50.2L85.6 43.4C85.5 43.1 85.3 42.9 84.5 42.9C83.7 42.9 83.5 43.1 83.4 43.4L80.8 50.2C80.8 50.4 81.2 50.7 81.7 50.7C82.1 50.7 82.4 50.5 82.6 49.9L83.1 48.7H85.9L86.4 49.9C86.6 50.5 87 50.7 87.4 50.7C87.8 50.8 88.3 50.4 88.2 50.2ZM94.8 48.4C94.8 46.9 93.6 46.4 92.2 46.1C91.3 45.9 90.6 45.8 90.6 45.1C90.6 44.5 91.1 44.2 91.9 44.2C92.7 44.2 93.5 44.6 93.9 45.1C93.9 45.1 94.6 44.7 94.6 44.2C94.6 43.9 94.4 43.6 94.1 43.4C93.7 43.1 92.9 42.8 91.9 42.8C90.1 42.8 89 43.7 89 45.2C89 46.8 90.2 47.2 91.4 47.5C92.3 47.7 93.1 47.8 93.1 48.5C93.1 49.1 92.6 49.4 91.7 49.4C90.8 49.4 89.9 48.9 89.5 48.4C89.4 48.3 88.8 48.8 88.8 49.3C88.8 49.6 88.9 49.9 89.3 50.2C89.8 50.6 90.6 50.9 91.7 50.9C93.7 50.9 94.8 50 94.8 48.4ZM102.7 43.7C102.7 43.2 102.4 42.9 102 42.9H101.7C101.2 42.9 101 43.2 101 43.7V46.1H97.7V43.7C97.7 43.2 97.4 42.9 96.9 42.9H96.7C96.2 42.9 96 43.2 96 43.7V50C96 50.5 96.3 50.8 96.7 50.8H97C97.5 50.8 97.8 50.5 97.8 50V47.6H101.1V50C101.1 50.5 101.3 50.8 101.8 50.8H102.1C102.6 50.8 102.8 50.5 102.8 50V43.7H102.7Z"
      fill="url(#outsputs_icon_2_linear)"
    />
    <defs>
      <linearGradient
        id="outputs_icon_0"
        x1="11.8826"
        y1="57.7607"
        x2="11.8826"
        y2="34.6424"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0E2DB" />
        <stop offset="0.0601397" stopColor="#F0D2CB" />
        <stop offset="0.2603" stopColor="#EFA19E" />
        <stop offset="0.4421" stopColor="#EF7C7F" />
        <stop offset="0.5994" stopColor="#EE5E6C" />
        <stop offset="0.7259" stopColor="#EF4661" />
        <stop offset="0.8058" stopColor="#EF3D5D" />
      </linearGradient>
      <linearGradient
        id="outsputs_icon_1_linear"
        x1="29.3822"
        y1="27.9333"
        x2="29.3822"
        y2="4.9831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0E2DB" />
        <stop offset="0.0944298" stopColor="#F0C0B9" />
        <stop offset="0.1979" stopColor="#EFA09D" />
        <stop offset="0.3069" stopColor="#EF8687" />
        <stop offset="0.4205" stopColor="#EF6F76" />
        <stop offset="0.5401" stopColor="#EE5C6B" />
        <stop offset="0.6684" stopColor="#EF4B63" />
        <stop offset="0.8117" stopColor="#EF415E" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
      <linearGradient
        id="outsputs_icon_2_linear"
        x1="88.1509"
        y1="62.313"
        x2="88.1509"
        y2="38.4438"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F0E2DB" />
        <stop offset="0.0944298" stopColor="#F0C0B9" />
        <stop offset="0.1979" stopColor="#EFA09D" />
        <stop offset="0.3069" stopColor="#EF8687" />
        <stop offset="0.4205" stopColor="#EF6F76" />
        <stop offset="0.5401" stopColor="#EE5C6B" />
        <stop offset="0.6684" stopColor="#EF4B63" />
        <stop offset="0.8117" stopColor="#EF415E" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
    </defs>
  </svg>
);
