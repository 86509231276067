export const CloudsIcon = ({ height = 49, style }) => (
  <svg
    width={Math.abs(height * 1.7)}
    height={height}
    viewBox="0 0 98 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M83.3 25.7C80.7 16.8 72.7 9.69995 64 9.69995C63.9 9.69995 63.8 9.69995 63.6 9.69995H59.1C59 9.69995 58.9 9.69995 58.8 9.69995C58.7 9.69995 58.6 9.69995 58.4 9.69995H56.8V9.79995C49.2 10.7 42.4 17 40 25.2C38.5 25.2 36.9 25.2 35.4 25.2C28.2 25.3 22 31.4999 22.4 38.7999C22.9 46.0999 29.5 52.6 36.3 52.9C43.5 53.2 50.6 53.2999 57.8 53.2999C65 53.2999 72.1 53.2 79.3 52.9C86.1 52.6 92.8 46.1999 93.2 38.7999C93.4 32.5999 89 27.3 83.3 25.7Z"
      fill="url(#clouds_icon)"
    />
    <path
      d="M67.2 20.5C64.3 10.2 55 2.09997 45 1.99997C35.2 1.89997 26.3 9.69997 23.3 19.8C21.5 19.8 19.8 19.8 18 19.9C9.70001 20 2.60001 27.1 3.00001 35.5C3.50001 44 11.1 51.3 19 51.7C33.5 52.3 48 52.3 62.5 51.7C70.3 51.3 78 44 78.5 35.5C78.9 28.4 73.8 22.3 67.2 20.5Z"
      stroke="#18244D"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M93.1 21.5C95.53 21.5 97.5 19.53 97.5 17.1C97.5 14.6699 95.53 12.7 93.1 12.7C90.6699 12.7 88.7 14.6699 88.7 17.1C88.7 19.53 90.6699 21.5 93.1 21.5Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="clouds_icon"
        x1="57.7289"
        y1="54.0557"
        x2="57.7289"
        y2="7.31405"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1432" stopColor="#F0E2DB" />
        <stop offset="0.1569" stopColor="#F0DED7" />
        <stop offset="0.328" stopColor="#EFB1AB" />
        <stop offset="0.4924" stopColor="#EF8C8C" />
        <stop offset="0.6461" stopColor="#EF6F76" />
        <stop offset="0.7864" stopColor="#EF5668" />
        <stop offset="0.9089" stopColor="#EF4460" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="93.11"
        y1="21.6679"
        x2="93.11"
        y2="12.2729"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1382" stopColor="#FEE7DF" />
        <stop offset="0.2174" stopColor="#FAC4BD" />
        <stop offset="0.3077" stopColor="#F7A39F" />
        <stop offset="0.4027" stopColor="#F48788" />
        <stop offset="0.5015" stopColor="#F27077" />
        <stop offset="0.6054" stopColor="#F15C6B" />
        <stop offset="0.7164" stopColor="#F04B63" />
        <stop offset="0.8397" stopColor="#EF415E" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
    </defs>
  </svg>
);
