const content = {
  /** Navigaion bar */
  "nav-home": { eng: "Home" },
  "nav-benefit": { eng: "Benefits" },
  "nav-features": { eng: "Features" },
  "nav-solutions": { eng: "Solutions" },
  "early-access": { eng: "Request early access" },
  "how-it-works": { eng: "See how it works" },

  /** Main section */
  "main-title": { eng: "Power for streamers.", prt: "Power for streamers." },
  "main-content": {
    eng:
      "Gencode delivers fast and reliable encoding power in the cloud " +
      "without having to manage and scale expensive " +
      "hardware and software.",
  },

  /** Time to market section */
  "time-to-market-title": {
    eng: "Optimize the time to market with our HyperFast encoding",
  },
  "time-to-market-subtitle": {
    eng: "Our HyperFast and cost-effective encoding solution leverage from:",
  },
  "time-to-market-content-1": {
    eng: "Always ready encoders for fast start, avoiding long time queues.",
  },
  "time-to-market-content-2": {
    eng: "Paralell processing using a chunk-based video encoding technology.",
  },
  "time-to-market-content-3": {
    eng:
      "Reduce encoding speed and increase speed-to-market " +
      "from hours to minutes, offering a price-performance when " +
      "need it, with no waste.",
  },

  /**Regions section */
  "regions-title": { eng: "Cloud Regions" },
  "regions-content": {
    eng:
      "We have a multicloud network of several global infrastructure partners. " +
      "Delivering latency close to milliseconds and encoding content at maximum speed " +
      "at each point of the processing workflow.",
  },
  "regions-content2": {
    eng:
      "We are available globally from 10 regions: East Coast of USA, " +
      "West Coast of USA, Toronto in Canada, São Paulo in Brazil, " +
      "London in UK, Frankfurt in Germany, Tokyo in Japan, " +
      "Hong Kong, Singapore and Sidney in Australia.",
  },

  /**Codecs section */
  "codecs-title": { eng: "Latest formats and codecs" },
  "codecs-content": {
    eng:
      "By optimizing video quality at the lowest bitrates, " +
      "we enable customers to deliver stunning quality video " +
      "to their viewers, on the widest range of devices in " +
      "market. Achieve significant bandwidth savings and " +
      "quality improvements with our state of the art " +
      "encoding technology. ",
  },
  "codecs-content2": {
    eng:
      "We support an ever growing number of the latest " +
      "codecs, including, AV1, VP9, H.264, HEVC, VVC, " +
      "MKV, WebM, MPEG-DASH and HLS. ",
  },

  /**Api section */
  "api-title": { eng: "Robust API and UI" },
  "api-content": {
    eng:
      "Start transcoding in a few simple steps. Our UI, " +
      "API, and SDKs make it easy for you easily build " +
      "you own cloud video service.\n\nOur robust API allows " +
      "more advanced users to customize every element " +
      "of their output videos to fit their exact needs.",
  },

  /** Quality section */
  "quality-title": {
    eng: "Crystal clear video quality with the lowest bitrates",
  },
  "quality-content": {
    eng:
      "The video above demonstrates the power of Gencode,  " +
      "reducing bitrates by 30% with no visible loss of quality. " +
      "The size of the video data is reduced enormously, " +
      "archieving big savings in storage and streaming data " +
      "transfer over CDNs while maintaining crystal clear video  " +
      "quality with the lowest bitrate on the market.",
  },

  /**How it works section */
  "key-how-it-works-title": { eng: "How it works" },
  "key-how-it-works-content": {
    eng:
      "Simply submit source videos with the desired settings and " +
      "let Gencode take care of the whole process in four simple steps, without worrying " +
      "about managing the encoding infrastructure, the final quality or reliability.",
  },
  "key-how-it-works-columnA-title": { eng: "Source Videos" },
  "key-how-it-works-columnA-content": {
    eng:
      "Submit original videos from your own storage, downloadable links or watchable folders in S3 buckets.",
  },
  "key-how-it-works-columnB-title": { eng: "Encode" },
  "key-how-it-works-columnB-content": {
    eng:
      "Gencode efficiently transcode those videos in various output formats to support playback on a wide range of devices at varying resolutions.",
  },
  "key-how-it-works-columnC-title": { eng: "Output Formats" },
  "key-how-it-works-columnC-content": {
    eng:
      "Output into multiple modern streaming formats including HLS, DASH and MPEG-4.",
  },
  "key-how-it-works-columnD-title": { eng: "Storage" },
  "key-how-it-works-columnD-content": {
    eng:
      "Encoded videos are automatically stored at leading storage providers of choice, including Amazon, Google, Microsoft, IBM, Digital Ocean and more.",
  },

  /** Managed Cloud Key features section */
  "key-features-title": { eng: "Managed Cloud key features" },

  "key-features-columnA-title": {
    eng: "Real-time access to encoding power",
  },
  "key-features-columnA-content": {
    eng:
      "Our intelligent Balance and Scaling algorithms can accommodate any size of encoding workflow. From 5 to 5,000 jobs our technology can automatically scale the resources needed to process jobs simultaneously in a fraction of real time.",
  },

  "key-features-columnB-title": {
    eng: "Broadcast-grade experience",
  },
  "key-features-columnB-content": {
    eng:
      "Enable immersive viewing experiences, handle multichannel audio, subtitles and closed captions, encode into professional broadcast formats, apply AES-128 encryption and DRM, seamlessly integrate with our easy-to-use API and world-class integration support streamline.",
  },

  "key-features-columnC-title": {
    eng: "Modern video formats",
  },
  "key-features-columnC-content": {
    eng:
      "Always up to date with the latest codecs, supporting video formats for broadcast or streaming, including HEVC, VP9 and MPEG-4 standards, adaptive bitrate formats including HLS and DASH in resolutions up to 4K and 8K, also with HDR (High Dynamic Range).",
  },

  /**Benefits section */
  "benefits-title": { eng: "Next-generation cloud encoding" },
  "benefits-content": {
    eng:
      "Gencode is built for enterprises seeking higher encoding performance, " +
      "lower costs, autonomous services, integrated security and better " +
      "price-performance for cloud native workloads.",
  },
  "benefits-columnA-title": { eng: "Flexible Global Delivery" },
  "benefits-columnA-content": {
    eng:
      "Deliver to multiple CDNs and YouTube in one step. You can easily send your videos to Amazon S3 " +
      "and CloudFront, Google Storage, Azure Storage, Digital Ocean Spaces, Wasabi, Backblaze and any " +
      "S3 compatible storage cloud.\n\nYou can also utilize Aspera, a cutting edge software technology " +
      "that moves large volumes of data at maximum speed for larger bulk projects.",
  },

  "benefits-columnB-title": { eng: "Reduce costs and enhance performance" },
  "benefits-columnB-content": {
    eng:
      "Our efficient cloud encoding technology provides speed and flexibility for a low and easy " +
      "to understand cost.\n\nStay competitive in the evolving streaming video business by optimizing " +
      "operations, reducing costs and risk, enhancing compute platform performance and increasing margins and ROI.",
  },

  "benefits-columnC-title": { eng: "Latest codecs" },
  "benefits-columnC-content": {
    eng:
      "By optimizing video quality at the lowest bitrates, we enable customers to deliver " +
      "stunning quality video to their viewers, on the widest range of devices in market. " +
      "Achieve significant bandwidth savings and quality improvements with our state of the " +
      "art encoding technology.\n\nWe support an ever growing number of the latest codecs, " +
      "including, AV1, VP9, H.264, HEVC, VVC, MKV, WebM, MPEG-DASH and HLS.",
  },

  "benefits-columnD-title": {
    eng: "Consistency and availability",
  },
  "benefits-columnD-content": {
    eng:
      "We built from the ground up a modern architecture to deliver new levels of " +
      "performance and consistency even to the most demanding cloud encoding " +
      "workloads.\n\nOur service was designed to combine on-demand availability with " +
      "cloud economics and scale that can support thousands of encoding jobs running in many cores simultaneously.",
  },

  /**Solutions section */
  "solutions-title": {
    eng: "Solutions to empower every modern video streaming endeavour",
  },
  "solutions-content": {
    eng:
      "Gencode is an SaaS that delivers high-performance file-based video encoding solutions " +
      "for every workload. Allowing consolidated companies and " +
      "fast-growing startups, that are building the next generation of video platforms, to easily " +
      "and reliably encode on-demand content for every screen, device and market.",
  },

  "managed-cloud-title": { eng: "Managed Cloud Solution" },
  "solutions-columnA-title": { eng: "Managed Cloud Solution" },
  "solutions-columnA-content": {
    eng:
      "Fully managed cloud-hosted with unlimited video encoding power in the cloud. " +
      "Automated and elastic so you get the encoding resources that you need, when you need.",
  },

  "solutions-columnB-title": { eng: "Cloud Connect Solution" },
  "solutions-columnB-content": {
    eng:
      "Deploy to the public cloud provider of your choice. A flexible way to access encoding " +
      "power that seamlessly scale from simple to more robust as your streaming needs, and " +
      "audiences, evolve and grow.",
  },

  /**Hyperfast section */
  "hyperfast-title": {
    eng: "HyperFast and cost-effective encoding solution",
  },
  "hyperfast-content": {
    eng:
      "HyperFast significantly improves the workflow efficiency by dividing  " +
      "large source video in small pieces, and then do all the transcoding  " +
      "and packaging in parallel before reassembling the pieces together.",
  },
  "hyperfast-columnA-title": { eng: "Source Video" },
  "hyperfast-columnA-content": {
    eng:
      "High resolution video sources take a special advantage of our HyperFast technology, " +
      "including 4K, 8K and HDR.",
  },
  "hyperfast-columnB-title": { eng: "Split into chunks" },
  "hyperfast-columnB-content": {
    eng:
      "HyperFast starts by breaking the video source into different segments.",
  },
  "hyperfast-columnC-title": { eng: "Encode chunks" },
  "hyperfast-columnC-content": {
    eng:
      "Each segment is encoded atthe same time in a individual compute " +
      "instance, acellerating the overall encoding time.",
  },
  "hyperfast-columnD-title": { eng: "Reassamble" },
  "hyperfast-columnD-content": {
    eng:
      "After encoding and packaging, the chunks are reassembled " +
      "to distribution.",
  },
};

const language = "eng";

export const get = (key) => content[key][language];
