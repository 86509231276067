export const SourceVideoIcon = ({ height = 46, style }) => (
  <svg
    width={Math.abs(height * 1.55)}
    height={height}
    viewBox="0 0 88 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M37.2 17.2001C37.2 13.9001 39.4 12.7001 42.2 14.6001L58.1 25.3001C60.8 27.1001 60.8 30.2001 58.1 32.0001L42.2 42.7001C39.5 44.5001 37.2 43.4001 37.2 40.1001V17.2001Z"
      fill="url(#source_video_icon_gradient)"
    />
    <path
      d="M77.8 52.8999C55.5 55.3999 33.1 55.3999 10.8 52.8999C8.59998 52.5999 6.19998 50.6999 5.59998 48.4999C2.09998 35.0999 2.09998 21.6999 5.59998 8.2999C6.19998 6.1999 8.49998 4.1999 10.8 3.8999C33.1 1.3999 55.5 1.3999 77.8 3.8999C80 4.1999 82.4 6.1999 83 8.2999C86.5 21.6999 86.5 35.0999 83 48.4999C82.4 50.5999 80 52.5999 77.8 52.8999Z"
      stroke="#18244D"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M37.4 22.3C37.4 20.1 38.9 19.3 40.7 20.5L49.2 26.2C51 27.4 51 29.4 49.2 30.7L40.7 36.4C38.9 37.6 37.4 36.8 37.4 34.6V22.3Z"
      fill="#18244D"
    />
    <defs>
      <linearGradient
        id="source_video_icon_gradient"
        x1="47.8298"
        y1="40.3557"
        x2="48.9222"
        y2="17.4144"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1635" stopColor="#F0E2DB" />
        <stop offset="0.3713" stopColor="#EFADA8" />
        <stop offset="0.5367" stopColor="#EF8989" />
        <stop offset="0.6181" stopColor="#EF7B7F" />
        <stop offset="0.6351" stopColor="#EF787C" />
        <stop offset="0.7894" stopColor="#EE5B6B" />
        <stop offset="0.9165" stopColor="#EF4661" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
    </defs>
  </svg>
);
