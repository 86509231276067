/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch } from "react-redux";
import { useAppState } from "../../reducers/ui/app/selectors";

import { Space, Drawer } from "antd";
import { Menu as HamburgerMenu, ChevronRight } from "react-feather";
import { BlogLink } from "./BlogLink";
import { NavHeader, MenuHashLink, MenuItem } from "./Navbar";

import { toggleMenu, hideMenu } from "../../reducers/ui/app/reducer";

import { get } from "../../localization/texts";

export const MobileBar = () => {
  const dispatch = useDispatch();
  const close = () => dispatch(hideMenu());
  const { menuVisible: isMenuVisible } = useAppState();

  return (
    <>
      <a onClick={() => dispatch(toggleMenu())}>
        <HamburgerMenu />
      </a>

      <Drawer
        width="85%"
        placement="right"
        closable={false}
        onClose={close}
        visible={isMenuVisible}
        bodyStyle={{
          color: "var(--g-white)",
          background: "var(--gencode-red)",
          fontSize: "2em",
        }}
      >
        <a onClick={() => dispatch(toggleMenu())}>
          <NavHeader>
            <HamburgerMenu />
          </NavHeader>
        </a>
        <Space
          direction="vertical"
          size={40}
          style={{ padding: "15%", width: "100%", alignItems: "flex-end" }}
        >
          <Link to="/#t" onClick={close}>
            {get("nav-home")}
          </Link>

          <Link to="/#benefits" onClick={close}>
            {get("nav-benefit")}
          </Link>

          <BlogLink>
            <MenuItem light onClick={close}>
              Blog
            </MenuItem>
          </BlogLink>

          <Link to="/#solutions" onClick={close}>
            <MenuItem light onClick={close}>
              {get("nav-solutions")}
            </MenuItem>
          </Link>

          <SubLink to="/solutions/managed-cloud#t" onClick={close}>
            Managed Cloud
          </SubLink>

          <SubLink to="/solutions/managed-cloud#t" onClick={close}>
            Connect Cloud
          </SubLink>
        </Space>
      </Drawer>
    </>
  );
};

const SubLink = ({ to, onClick, children }) => (
  <Link to={to} onClick={onClick} fontSize="0.8em">
    <ChevronRight size="15px" style={{ marginRight: 8 }} />
    {children}
  </Link>
);

const Link = ({ children, onClick, to, fontSize }) => (
  <MenuHashLink to={to} scroll={false}>
    <MenuItem light fontSize={fontSize} onClick={onClick}>
      {children}
    </MenuItem>
  </MenuHashLink>
);
