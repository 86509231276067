export const GlobalIcon = ({ style }) => (
  <svg
    width="60"
    height="45"
    style={style}
    viewBox="0 0 86 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.7 27.4999C33.1 27.2999 31.5 27.2999 29.9 27.4999C28.1 27.6999 26.3 29.4999 26.1 31.2999C25.9 32.8999 25.9 34.4999 26.1 36.0999C26.3 37.8999 28.1 39.6999 29.9 39.8999C30.7 39.9999 31.5 39.9999 32.3 39.9999C33.1 39.9999 33.9 39.9999 34.7 39.8999C36.5 39.6999 38.3 37.8999 38.5 36.0999C38.7 34.4999 38.7 32.8999 38.5 31.2999C38.2 29.4999 36.5 27.7999 34.7 27.4999ZM37 35.8999C36.8 36.9999 35.6 38.2999 34.5 38.3999C33 38.5999 31.6 38.5999 30.1 38.3999C29 38.1999 27.7 36.9999 27.6 35.8999C27.4 34.3999 27.4 32.8999 27.6 31.4999C27.8 30.3999 29 29.0999 30.1 28.9999C30.8 28.8999 31.6 28.8999 32.3 28.8999C33 28.8999 33.8 28.8999 34.5 28.9999C35.6 29.1999 36.9 30.3999 37 31.4999C37.2 32.9999 37.2 34.4999 37 35.8999Z"
      fill="#432E7F"
    />
    <path
      d="M60 1.89995C58.9 0.699953 57.3 -4.64655e-05 55.7 -4.64655e-05C54.1 -0.100046 52.5 0.599954 51.4 1.69995C49 3.99995 48.9 7.79995 51.2 10.3C52.4 11.6 54 12.2 55.6 12.2C57.1 12.2 58.6 11.6 59.8 10.5C62.2 8.19995 62.3 4.29995 60 1.89995ZM58.7 9.39995C56.9 11.1 54 11.1 52.2 9.19995C51.4 8.29995 51 7.19995 51 5.99995C51 4.79995 51.5 3.59995 52.4 2.79995C53.3 1.99995 54.4 1.49995 55.5 1.49995H55.6C56.8 1.49995 58 1.99995 58.8 2.89995C59.6 3.79995 60.1 4.89995 60.1 6.19995C60.1 7.39995 59.6 8.49995 58.7 9.39995Z"
      fill="#432E7F"
    />
    <path
      d="M83.8 29.7999C82.7 28.5999 81.2 27.9999 79.6 27.8999C78 27.7999 76.4 28.4999 75.3 29.5999C74.1 30.6999 73.5 32.1999 73.4 33.7999C73.4 35.3999 74 36.9999 75.1 38.0999C76.2 39.2999 77.7 39.8999 79.3 39.9999C79.4 39.9999 79.4 39.9999 79.5 39.9999C81.1 39.9999 82.5 39.3999 83.7 38.2999C84.9 37.1999 85.5 35.6999 85.6 34.0999C85.5 32.4999 84.9 30.9999 83.8 29.7999ZM82.5 37.2999C81.6 38.0999 80.5 38.5999 79.2 38.5999C78 38.5999 76.8 38.0999 76 37.1999C75.2 36.2999 74.7 35.1999 74.7 33.8999C74.7 32.6999 75.2 31.4999 76.1 30.6999C77 29.8999 78.1 29.3999 79.2 29.3999H79.3C80.5 29.3999 81.7 29.8999 82.5 30.7999C83.3 31.6999 83.8 32.7999 83.8 34.0999C83.9 35.2999 83.4 36.3999 82.5 37.2999Z"
      fill="#432E7F"
    />
    <path
      d="M57.5 59.7998C56.3 60.8998 55.7 62.3998 55.6 63.9998C55.6 65.5998 56.2 67.1998 57.3 68.2998C58.5 69.5998 60.1 70.1998 61.7 70.1998C63.2 70.1998 64.7 69.5998 65.9 68.4998C68.3 66.1998 68.4 62.2998 66.1 59.8998C63.8 57.5998 59.9 57.4998 57.5 59.7998ZM64.9 67.4998C63.1 69.1998 60.2 69.1998 58.4 67.2998C57.6 66.3998 57.1 65.2998 57.1 63.9998C57.1 62.7998 57.6 61.5998 58.5 60.7998C59.4 59.9998 60.5 59.4998 61.7 59.4998H61.8C63 59.4998 64.2 59.9998 65 60.8998C66.8 62.8998 66.7 65.7998 64.9 67.4998Z"
      fill="#432E7F"
    />
    <path
      d="M7.40002 20.6C-2.79998 31.2 -2.39998 48.2 8.20002 58.4C13.2 63.2 19.8 65.8 26.7 65.8C26.9 65.8 27.1 65.8 27.3 65.8C34.4 65.7 41.1 62.7 46 57.6C56.2 47 55.9 30 45.3 19.8C34.6 9.60004 17.6 10 7.40002 20.6ZM8.90002 22C9.60002 21.3 10.4 20.6 11.1 19.9C12.3 27.9 10.2 31.1 5.40002 36C3.90002 37.5 2.70002 39 2.10002 40.6C1.70002 33.9 3.90002 27.2 8.90002 22ZM27.2 63.9C20.6 64 14.4 61.6 9.60002 57C8.50002 55.9 7.50002 54.8 6.70002 53.6C7.20002 52.8 7.90002 52.2 8.70002 51.8C10.5 51 12.6 51.5 14.2 53.1C19 57.7 26.3 61.7 32.8 63.2C31 63.6 29.1 63.8 27.2 63.9ZM44.6 56.3C42.1 58.9 39.2 60.9 35.9 62.1C34.3 61.9 32.6 61.6 30.8 61.1C25.1 59.4 19.4 56.1 15.2 52C13.1 50 10.4 49.4 8.00002 50.5C7.10002 50.9 6.30002 51.6 5.70002 52.4C4.60002 50.7 3.80002 48.9 3.20002 47.1C2.00002 42.7 3.30002 40.2 6.40002 37.2C11.6 32 14 28.3 12.4 19C16.6 16 21.6 14.5 26.7 14.5C32.9 14.5 39 16.8 43.8 21.4C53.7 30.7 54 46.4 44.6 56.3Z"
      fill="#432E7F"
    />
    <path
      d="M42.8 19C42.6 19 42.4 18.9 42.3 18.8C42 18.5 42 18 42.3 17.7L44.6 15.5C44.9 15.2 45.4 15.2 45.7 15.5C46 15.8 46 16.3 45.7 16.6L43.4 18.8C43.2 19 43 19 42.8 19ZM47.5 14.6C47.3 14.6 47.1 14.5 47 14.4C46.7 14.1 46.7 13.6 47 13.3L49.3 11.1C49.6 10.8 50.1 10.8 50.4 11.1C50.7 11.4 50.7 11.9 50.4 12.2L48 14.4C47.8 14.5 47.7 14.6 47.5 14.6Z"
      fill="#4FAABB"
    />
    <path
      d="M39 22.6C38.8 22.6 38.6 22.5 38.5 22.4C38.2 22.1 38.2 21.6 38.5 21.3L39.9 19.9C40.2 19.6 40.7 19.6 41 19.9C41.3 20.2 41.3 20.7 41 21L39.6 22.4C39.4 22.5 39.2 22.6 39 22.6Z"
      fill="#4FAABB"
    />
    <path
      d="M40.8 47.9999C40.6 47.9999 40.5 47.8999 40.3 47.7999L38.7 46.5999C38.4 46.2999 38.3 45.8999 38.6 45.4999C38.9 45.1999 39.3 45.0999 39.7 45.3999L41.3 46.5999C41.6 46.8999 41.7 47.2999 41.4 47.6999C41.2 47.8999 41 47.9999 40.8 47.9999Z"
      fill="#4FAABB"
    />
    <path
      d="M54.7 58.8999C54.5 58.8999 54.4 58.7999 54.2 58.6999L50.8 55.9999C50.5 55.6999 50.4 55.2999 50.7 54.8999C51 54.5999 51.4 54.4999 51.8 54.7999L55.3 57.4999C55.6 57.7999 55.7 58.1999 55.4 58.5999C55.2 58.7999 55 58.8999 54.7 58.8999ZM47.8 53.4999C47.6 53.4999 47.5 53.3999 47.3 53.2999L43.8 50.5999C43.5 50.2999 43.4 49.8999 43.7 49.4999C44 49.1999 44.4 49.0999 44.8 49.3999L48.3 52.0999C48.6 52.3999 48.7 52.7999 48.4 53.1999C48.2 53.3999 48 53.4999 47.8 53.4999Z"
      fill="#4FAABB"
    />
    <path
      d="M70.7 35.6L66.5 35.5C66.1 35.5 65.8 35.1 65.8 34.7C65.8 34.3 66.1 34 66.6 34L70.8 34.1C71.2 34.1 71.5 34.5 71.5 34.9C71.5 35.3 71.1 35.6 70.7 35.6ZM62.4 35.3C62.3 35.3 62.3 35.3 62.4 35.3L58.2 35.2C57.8 35.2 57.5 34.8 57.5 34.4C57.5 34 57.8 33.6 58.3 33.7L62.5 33.8C62.9 33.8 63.2 34.2 63.2 34.6C63.1 35 62.8 35.3 62.4 35.3ZM54 35.1L49.8 35C49.4 35 49.1 34.6 49.1 34.2C49.1 33.8 49.4 33.5 49.9 33.5L54.1 33.6C54.5 33.6 54.8 34 54.8 34.4C54.7 34.8 54.4 35.1 54 35.1Z"
      fill="#4FAABB"
    />
    <path
      d="M45.6 34.7999L43.6 34.7C43.2 34.7 42.9 34.3 42.9 33.9C42.9 33.5 43.2 33.2 43.7 33.2L45.7 33.2999C46.1 33.2999 46.4 33.6999 46.4 34.0999C46.4 34.4999 46 34.7999 45.6 34.7999Z"
      fill="#4FAABB"
    />
  </svg>
);
