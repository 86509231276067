import { useMemo } from "react";
import ReactHlsPlayer from "react-hls-player";
import styled from "styled-components";

export const VideoPlayer = ({
  src,
  poster,
  scaleBehavior = "fit",
  hideShadow = false,
}) => {
  const player = useMemo(() => {
    const style = hideShadow ? { boxShadow: "none" } : undefined;
    return (
      <ReactHlsPlayer
        src={src}
        poster={poster}
        muted
        autoPlay
        width="100%"
        height="100%"
        loop={true}
        className="g-video-container"
        style={style}
      />
    );
  }, [src, poster, hideShadow]);

  return (
    <VideoWrapper>
      <Video height={scaleBehavior === "fit" ? "auto" : "100%"}>{player}</Video>
    </VideoWrapper>
  );
};

const VideoWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  transition: all 0.6s;
`;

const Video = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  background: transparent;
  border-radius: 6px;
  transition: all 0.6s;
`;
