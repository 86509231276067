import React from "react";

export const Logo = ({ color = "--g-white" }) => {
  const c = `var(${color})`;

  return (
    <svg
      width="120"
      height="30"
      viewBox="0 0 110 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: 2 }}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M23.0907 5.29398C26.7522 5.29398 29.1827 7.97248 29.1827 11.8957V12.1163C29.1827 12.6835 28.9958 12.9041 28.3881 12.9041H19.0241C19.0241 13.0616 19.0553 13.2349 19.0708 13.3925C19.4448 15.5511 21.0963 16.9691 23.34 16.9691C25.5524 16.9691 26.6587 15.9607 27.3286 14.7948C27.3754 14.7475 28.6997 14.9838 28.6997 16.134C28.6997 16.7012 28.2323 17.4418 27.2975 18.0405C26.3782 18.6392 25.0227 19.1119 23.2932 19.1119C19.289 19.1119 16.5935 16.3546 16.5935 12.2738C16.5935 8.25608 19.3357 5.29398 23.0907 5.29398ZM23.0595 7.34224C20.9717 7.34224 19.3669 8.90207 19.0553 11.0764H26.6431C26.5184 8.80754 25.1629 7.34224 23.0595 7.34224Z"
          fill={c}
        />
        <path
          d="M43.8442 10.9031V17.5521C43.8442 18.5289 43.3456 18.8283 42.6756 18.8283H42.3796C41.6941 18.8283 41.2111 18.5289 41.2111 17.5521V11.2339C41.2111 9.02812 40.136 7.65736 38.2819 7.65736C36.6147 7.65736 35.3527 8.58696 34.7139 9.94196V17.5521C34.7139 18.5289 34.2153 18.8283 33.5453 18.8283H33.2493C32.5638 18.8283 32.0807 18.5289 32.0807 17.5521V5.78241C32.0807 5.70363 32.5014 5.48305 33.0312 5.48305C33.7479 5.48305 34.6204 5.86119 34.6672 7.61009C35.5708 6.23933 36.9575 5.29398 38.8895 5.29398C42.2861 5.29398 43.8442 7.65736 43.8442 10.9031Z"
          fill={c}
        />
        <path
          d="M52.7564 5.29398C54.6573 5.29398 55.9972 5.8927 56.9009 6.66474C57.7734 7.40526 58.1941 8.30335 58.1941 8.93359C58.1941 10.0523 56.7139 10.2728 56.636 10.131C56.0595 8.68149 54.9689 7.56282 52.8032 7.56282C50.4349 7.56282 48.6275 9.5008 48.6275 12.1793C48.6275 14.8736 50.3882 16.843 52.8032 16.843C54.9377 16.843 56.044 15.7244 56.636 14.2748C56.7139 14.133 58.1941 14.3221 58.1941 15.5038C58.1941 16.1025 57.7734 17.0006 56.8697 17.7411C55.9972 18.4974 54.6261 19.1119 52.7564 19.1119C48.9236 19.1119 46.1658 16.2286 46.1658 12.1793C46.1658 8.1773 48.9703 5.29398 52.7564 5.29398Z"
          fill={c}
        />
        <path
          d="M66.4518 5.29398C70.3314 5.29398 73.0737 8.1773 73.0737 12.2266C73.0737 16.2286 70.3314 19.0961 66.4207 19.0961C62.4943 19.0961 59.7677 16.2286 59.7677 12.1635C59.7833 8.16155 62.5255 5.29398 66.4518 5.29398ZM66.4363 7.4998C64.0057 7.4998 62.1827 9.46929 62.1827 12.1635C62.1827 14.8578 64.0057 16.8746 66.4363 16.8746C68.8669 16.8746 70.6586 14.8893 70.6586 12.2108C70.6431 9.48504 68.8824 7.4998 66.4363 7.4998Z"
          fill={c}
        />
        <path
          d="M74.772 12.1951C74.772 8.05126 77.2805 5.29398 80.7861 5.29398C82.8428 5.29398 84.4009 6.27084 85.1332 7.45254V0.299362C85.1332 0.220582 85.585 0 86.0836 0C86.8938 0 87.7507 0.441165 87.7507 2.31612V17.5678C87.7507 18.5447 87.2833 18.844 86.5822 18.844H86.3173C85.6317 18.844 85.1487 18.5447 85.1487 17.5678V16.5594C84.1516 18.135 82.7026 19.0961 80.6927 19.0961C77.2961 19.0961 74.772 16.3389 74.772 12.1951ZM85.1643 10.2256C84.5099 8.55545 83.2167 7.53132 81.2536 7.53132C78.8853 7.53132 77.2961 9.39051 77.2961 12.1951C77.2961 14.9208 78.932 16.8588 81.2068 16.8588C83.3102 16.8588 85.1643 15.2517 85.1643 13.0144V10.2256Z"
          fill={c}
        />
        <path
          d="M96.6941 5.29398C100.356 5.29398 102.786 7.97248 102.786 11.8957V12.1163C102.786 12.6835 102.599 12.9041 101.992 12.9041H92.6275C92.6275 13.0616 92.6587 13.2349 92.6742 13.3925C93.0482 15.5511 94.6997 16.9691 96.9434 16.9691C99.1558 16.9691 100.262 15.9607 100.932 14.7948C100.979 14.7475 102.303 14.9838 102.303 16.134C102.303 16.7012 101.836 17.4418 100.901 18.0405C99.966 18.6392 98.6261 19.1119 96.8966 19.1119C92.8924 19.1119 90.1969 16.3546 90.1969 12.2738C90.1969 8.25608 92.9391 5.29398 96.6941 5.29398ZM96.6629 7.34224C94.5751 7.34224 92.9703 8.90207 92.6587 11.0764H100.262C100.122 8.80754 98.7663 7.34224 96.6629 7.34224Z"
          fill={c}
        />
        <path
          d="M108.052 18.2611C109.128 18.2611 110 17.3793 110 16.2916C110 15.2039 109.128 14.3221 108.052 14.3221C106.977 14.3221 106.105 15.2039 106.105 16.2916C106.105 17.3793 106.977 18.2611 108.052 18.2611Z"
          fill={c}
        />
        <path
          d="M7.44759 27.9825C12.4178 27.9825 15.3782 26.2178 15.3782 23.2715C15.3782 21.2547 13.9448 19.8839 11 19.0804C10.4858 18.9386 10.0028 18.8283 9.56657 18.7338C7.72805 18.2926 6.7153 18.0563 6.7153 17.1109C6.7153 16.8588 6.76204 16.6697 6.90227 16.4649L6.94901 16.3861H7.6034C11.3739 16.3861 14.1161 14.007 14.1161 10.7455C14.1161 9.51657 13.6643 8.31912 12.8074 7.29499L12.7139 7.1847L15.4094 4.30137C15.347 4.06503 14.8796 3.13544 13.9292 3.13544C13.2125 3.13544 12.745 3.46631 12.2309 4.31713L11.3272 5.9715L11.187 5.89272C10.0963 5.27824 8.84986 4.94736 7.57224 4.94736C3.89518 4.94736 1.12181 7.46831 1.12181 10.8243C1.12181 13.2192 2.63315 15.2044 5.04816 15.9765L5.31303 16.0553L5.11048 16.2443C4.73654 16.5752 4.30028 17.1267 4.30028 17.8514C4.30028 18.4502 4.48725 18.9071 4.86119 19.2222L5.11048 19.4428L4.78329 19.5058C1.83853 20.0257 0 21.6486 0 23.7284C0 26.6275 2.36827 27.9825 7.44759 27.9825ZM3.83286 10.7613C3.83286 8.53971 5.35977 6.99563 7.55666 6.99563C9.80028 6.99563 11.3739 8.53971 11.3739 10.7613C11.3739 12.9829 9.81587 14.5427 7.6034 14.5427C5.37535 14.5427 3.83286 12.9829 3.83286 10.7613ZM7.69689 20.656H7.72805L9.34844 21.0656C11.1402 21.5226 12.6048 22.011 12.6048 23.366C12.6048 25.0519 10.7507 26.013 7.50992 26.013C3.56799 26.013 2.74221 24.721 2.74221 23.6338C2.74221 21.9795 4.62748 20.8451 7.69689 20.656Z"
          fill={c}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="110" height="27.9825" fill={c} />
        </clipPath>
      </defs>
    </svg>
  );
};
