export const GencodeIcon = ({ height = 60, style }) => (
  <svg
    width={height}
    height={height}
    viewBox="0 0 79 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M31.4 51.2C31.4 52.9 32.7 54.7999 38.8 54.7999C43.8 54.7999 46.7 53.2999 46.7 50.7999C46.7 48.6999 44.4 47.9999 41.7 47.2999L39.2 46.7C34.4 46.9 31.4 48.7 31.4 51.2Z"
      fill="var(--gencode-red)"
    />
    <path
      d="M38.9 25.8C35.5 25.8 33.2 28.2 33.2 31.6C33.2 35.0001 35.6 37.4001 39 37.4001C42.4 37.4001 44.8 35.0001 44.8 31.6C44.7 28.2 42.3 25.8 38.9 25.8Z"
      fill="var(--gencode-red)"
    />
    <path
      d="M39.1 0C17.5 0 0 17.5 0 39.1C0 60.7 17.5 78.2 39.1 78.2C60.7 78.2 78.2 60.7 78.2 39.1C78.2 17.5 60.7 0 39.1 0ZM47 26.3C48.3 27.9 49 29.7 49 31.6C49 36.6 44.8 40.2 39 40.2H38L37.9 40.3C37.7 40.6 37.6 40.9 37.6 41.3C37.6 42.8 39.2 43.1 42 43.8C42.7 44 43.4 44.1 44.2 44.3C48.7 45.5 50.9 47.6 50.9 50.7C50.9 55.2 46.3 57.9 38.7 57.9C30.9 57.9 27.2 55.8 27.2 51.4C27.2 48.2 30 45.8 34.6 45L35.1 44.9L34.7 44.6C34.1 44.1 33.8 43.4 33.8 42.5C33.8 41.4 34.5 40.6 35.1 40L35.4 39.7L35 39.5C31.3 38.3 28.9 35.3 28.9 31.6C28.9 26.5 33.2 22.6 38.8 22.6C40.8 22.6 42.7 23.1 44.4 24L44.6 24.1L46 21.6C46.8 20.3 47.5 19.8 48.6 19.8C50.1 19.8 50.8 21.2 50.9 21.6L46.8 26L47 26.3Z"
      fill="var(--gencode-red)"
    />
  </svg>
);
