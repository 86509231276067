export const SourceHyperIcon = ({ height = 68, style }) => (
  <svg
    width={Math.abs(height * 1.27)}
    height={height}
    viewBox="0 0 118 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M96.2249 68.525C107.878 68.525 117.325 59.0782 117.325 47.425C117.325 35.7717 107.878 26.325 96.2249 26.325C84.5717 26.325 75.1249 35.7717 75.1249 47.425C75.1249 59.0782 84.5717 68.525 96.2249 68.525Z"
      fill="url(#hyper_icon_gradient)"
    />
    <path
      d="M83.625 89.525C58.625 90.625 33.625 90.625 8.625 89.525C5.525 89.425 2.925 86.725 2.825 83.725C1.725 58.725 1.725 33.725 2.825 8.725C2.925 5.625 5.525 3.025 8.625 2.825C33.625 1.725 58.625 1.725 83.625 2.825C86.725 2.925 89.325 5.625 89.425 8.625C90.525 33.625 90.525 58.625 89.425 83.625C89.325 86.725 86.625 89.425 83.625 89.525Z"
      stroke="#18244D"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M38.225 32.225C38.225 28.925 40.425 27.725 43.225 29.625L62.925 42.825C65.625 44.625 65.625 47.725 62.925 49.525L43.225 62.825C40.525 64.625 38.225 63.525 38.225 60.225V32.225Z"
      fill="url(#hyper_icon_gradient_1)"
    />
    <path
      d="M38.425 38.3249C38.425 36.1249 39.925 35.3249 41.725 36.5249L52.425 43.7249C54.225 44.9249 54.225 46.9249 52.425 48.2249L41.725 55.4249C39.925 56.6249 38.425 55.8249 38.425 53.6249V38.3249Z"
      fill="#18244D"
    />
    <defs>
      <linearGradient
        id="hyper_icon_gradient"
        x1="96.2311"
        y1="69.2492"
        x2="96.2311"
        y2="23.9899"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1635" stopColor="#F0E2DB" />
        <stop offset="0.2626" stopColor="#F0C8C1" />
        <stop offset="0.4561" stopColor="#EF9B98" />
        <stop offset="0.6336" stopColor="#EF787D" />
        <stop offset="0.789" stopColor="#EE5B6B" />
        <stop offset="0.9164" stopColor="#EF4661" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
      <linearGradient
        id="hyper_icon_gradient_1"
        x1="50.6164"
        y1="59.9287"
        x2="51.8965"
        y2="33.0483"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1635" stopColor="#F0E2DB" />
        <stop offset="0.3713" stopColor="#EFADA8" />
        <stop offset="0.5367" stopColor="#EF8989" />
        <stop offset="0.6181" stopColor="#EF7B7F" />
        <stop offset="0.6351" stopColor="#EF787C" />
        <stop offset="0.7894" stopColor="#EE5B6B" />
        <stop offset="0.9165" stopColor="#EF4661" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
    </defs>
  </svg>
);
