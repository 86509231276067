import styled from "styled-components";
import { useMediaQuery } from "react-responsive";

import { HashLink } from "react-router-hash-link";

import { Row, Col, Space } from "antd";

import { get } from "../../../../localization";

import { ArrowRight } from "react-feather";
import { ManagedCloudIcon, ConnectCloudIcon } from "../../../components/icons";
import { VideoPlayer } from "../../../components";

// Redux:
import { hideSolutionsSubmenu } from "../../../../reducers/ui/app/reducer";
import { useDispatch } from "react-redux";
import { baseUrl } from "../../../constants";

export const SolutionsBanners = ({
  hideShadows = false,
  hideVideo = false,
}) => (
  <Row gutter={[15, 15]}>
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={hideVideo ? 12 : 11}
      xl={hideVideo ? 12 : 9}
    >
      <Column
        icon={<ManagedCloudIcon />}
        title={get("solutions-columnA-title")}
        content={get("solutions-columnA-content")}
        linkTo="/solutions/managed-cloud#t"
      />
    </Col>
    <Col
      xs={24}
      sm={24}
      md={24}
      lg={hideVideo ? 12 : 11}
      xl={hideVideo ? 12 : 9}
    >
      <Column
        icon={<ConnectCloudIcon />}
        title={get("solutions-columnB-title")}
        content={get("solutions-columnB-content")}
        linkTo="/solutions/managed-cloud"
      />
    </Col>
    {!hideVideo && (
      <Col xs={24} sm={24} md={24} lg={2} xl={6}>
        <VideoPlayer
          hideShadow={hideShadows}
          src={`${baseUrl}/clouds/landing-cloud.gencoded`}
          poster={`${baseUrl}/clouds/landing-cloud.jpg`}
          scaleBehavior="crop"
        />
      </Col>
    )}
  </Row>
);

const Column = ({ title, content, icon, linkTo }) => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  return (
    <ColumnBox padding={isDesktop ? 40 : 20}>
      <HashLink
        to={linkTo}
        onClick={() => {
          dispatch(hideSolutionsSubmenu());
        }}
      >
        {icon}
        <Space direction="vertical" size={20} style={{ marginTop: 5 }}>
          <ColumnTitle>{title}</ColumnTitle>
          <ColumnContent>{content}</ColumnContent>
        </Space>
        <ArrowWrapper>
          <ArrowRight style={{ selfAlign: "right" }} />
        </ArrowWrapper>
      </HashLink>
    </ColumnBox>
  );
};

const ArrowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -5px;
`;

const ColumnBox = styled.div`
  color: var(--g-black);
  background: #efebe7;
  padding: ${(props) => props.padding && `${props.padding}px`};
  border-radius: 7px;

  height: 100%;
`;

const ColumnTitle = styled.div`
  font-size: 1.5em;
  font-weight: 700;
`;

const ColumnContent = styled.div`
  font-size: 1.16em;
  white-space: pre-wrap;
`;
