import styled from "styled-components";

export const Footer = () => {
  const content =
    "© 2021, Gencode, Inc. and paulo.digital. All rights reserved.";

  return <FooterInner>{content}</FooterInner>;
};

const FooterInner = styled.div`
  display: flex;
  padding: 3em;
  white-space: pre-wrap;
`;
