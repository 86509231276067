// Redux.
import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
// Utils.

// Slices for UI.
import appReducer from "./ui/app/reducer";

// (1) Combine reducers.
const reducer = combineReducers({
  ui: combineReducers({
    app: appReducer,
  }),
});

// (2) Configure middlewares.
const middlewares = [
  ...getDefaultMiddleware({
    serializableCheck: false,
  }),
];

// (3) Create and export store.
export const store = configureStore({
  reducer,
  middleware: middlewares,
});
