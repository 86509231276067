/* eslint-disable jsx-a11y/anchor-is-valid */
import styled from "styled-components";

import scrollIntoView from "scroll-into-view";
import { NavHashLink, HashLink } from "react-router-hash-link";
import { useMediaQuery } from "react-responsive";

import { Logo } from "../components/icons";
import { MobileBar } from "./MobileBar";
import { DesktopBar } from "./DesktopBar";

export const Navbar = () => {
  const isDesktop = useMediaQuery({
    query: "(min-device-width: 768px)",
  });

  return (
    <>
      <HashLink to="/#t">
        <Logo />
      </HashLink>

      {/* Dinamyc menu: mobile or desktop */}
      {isDesktop ? <DesktopBar /> : <MobileBar />}
    </>
  );
};

export const NavHeader = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  width: 100%;
`;

export const MenuHashLink = ({ to, children, scroll = true }) => (
  <NavHashLink
    smooth={scroll}
    to={to}
    scroll={(el) =>
      scrollIntoView(el, {
        time: !scroll ? 0 : 500,
        align: {
          top: 0,
          topOffset: 94,
        },
      })
    }
  >
    {children}
  </NavHashLink>
);

export const MenuItem = ({
  children,
  className,
  light = false,
  fontSize = "1.1em",
  onClick,
}) => {
  return (
    <MenuItemInner
      light={light}
      className={className}
      onClick={onClick}
      fontSize={fontSize}
    >
      {children}
    </MenuItemInner>
  );
};

const MenuItemInner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => (props.light ? "400" : "600")};

  &:hover {
    opacity: 0.6;
  }
`;
