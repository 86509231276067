import React from "react";
export const ManagedCloudIcon = ({ height = 38, style }) => (
  <svg
    width={Math.abs(height * 1.45)}
    height={height}
    viewBox="0 0 72 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M44.5 54.2C59.4669 54.2 71.6 42.0669 71.6 27.1C71.6 12.1331 59.4669 0 44.5 0C29.5331 0 17.4 12.1331 17.4 27.1C17.4 42.0669 29.5331 54.2 44.5 54.2Z"
      fill="url(#mccloud_icon_gradnient)"
    />
    <path
      d="M21 12.3C9.4 12.3 0 21.7 0 33.3C0 35.4 0.3 37.5001 0.9 39.4001C2 38.1001 3.6 37.1 5.3 36.6C5.1 35.6 5 34.5 5 33.3C5 24.5 12.2 17.3 21 17.3C29.8 17.3 37 24.5 37 33.3C37 42.1 29.8 49.3 21 49.3C19.1 49.3 17.2 48.9 15.5 48.3C14.8 50 13.6 51.4 12.1 52.3C14.8 53.6 17.8 54.3 21 54.3C32.6 54.3 42 44.9 42 33.3C42 21.7 32.6 12.3 21 12.3Z"
      fill="#18244D"
    />
    <path
      d="M7.50001 49.9001C10.151 49.9001 12.3 47.751 12.3 45.1C12.3 42.4491 10.151 40.3 7.50001 40.3C4.84905 40.3 2.70001 42.4491 2.70001 45.1C2.70001 47.751 4.84905 49.9001 7.50001 49.9001Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="mccloud_icon_gradnient"
        x1="44.4659"
        y1="55.2411"
        x2="44.4659"
        y2="-2.9309"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1635" stopColor="#F0E2DB" />
        <stop offset="0.2626" stopColor="#F0C8C1" />
        <stop offset="0.4561" stopColor="#EF9B98" />
        <stop offset="0.6336" stopColor="#EF787D" />
        <stop offset="0.789" stopColor="#EE5B6B" />
        <stop offset="0.9164" stopColor="#EF4661" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="7.50031"
        y1="50.0396"
        x2="7.50031"
        y2="39.7985"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1382" stopColor="#FEE7DF" />
        <stop offset="0.2174" stopColor="#FAC4BD" />
        <stop offset="0.3077" stopColor="#F7A39F" />
        <stop offset="0.4027" stopColor="#F48788" />
        <stop offset="0.5015" stopColor="#F27077" />
        <stop offset="0.6054" stopColor="#F15C6B" />
        <stop offset="0.7164" stopColor="#F04B63" />
        <stop offset="0.8397" stopColor="#EF415E" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
    </defs>
  </svg>
);
