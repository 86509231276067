export const AssembleHyperIcon = ({ height = 68, style }) => (
  <svg
    width={Math.abs(height * 1.14)}
    height={height}
    viewBox="0 0 106 93"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M12.2 57.9C18.9379 57.9 24.4 52.4379 24.4 45.7C24.4 38.9621 18.9379 33.5 12.2 33.5C5.46213 33.5 0 38.9621 0 45.7C0 52.4379 5.46213 57.9 12.2 57.9Z"
      fill="url(#assemble_hyper_gradient0)"
    />
    <path
      d="M103.1 83.7C104.2 58.7 104.2 33.7 103.1 8.69995C103 5.59995 100.3 2.99995 97.3 2.89995C72.3 1.79995 47.3 1.79995 22.3 2.89995C19.2 2.99995 16.6 5.69995 16.5 8.69995C15.4 33.7 15.4 58.7 16.5 83.7C16.6 86.8 19.3 89.4 22.3 89.5C47.3 90.6 72.3 90.6 97.3 89.5C100.3 89.4 102.9 86.7 103.1 83.7Z"
      stroke="#18244D"
      strokeWidth="4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M51.9 60.1C51.9 63.4 54.1 64.6 56.9 62.7L76.6 49.4C79.3 47.6 79.3 44.5 76.6 42.7L56.9 29.5C54.2 27.7 51.9 28.8 51.9 32.1V60.1Z"
      fill="url(#assemble_hyper_gradient1)"
    />
    <path
      d="M52.1 53.9999C52.1 56.1999 53.6 56.9999 55.4 55.7999L66.1 48.5999C67.9 47.3999 67.9 45.3999 66.1 44.0999L55.4 36.8999C53.6 35.6999 52.1 36.4999 52.1 38.6999V53.9999V53.9999Z"
      fill="#18244D"
    />
    <defs>
      <linearGradient
        id="assemble_hyper_gradient0"
        x1="12.2478"
        y1="33.062"
        x2="12.2478"
        y2="59.3125"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1635" stopColor="#F0E2DB" />
        <stop offset="0.2626" stopColor="#F0C8C1" />
        <stop offset="0.4561" stopColor="#EF9B98" />
        <stop offset="0.6336" stopColor="#EF787D" />
        <stop offset="0.789" stopColor="#EE5B6B" />
        <stop offset="0.9164" stopColor="#EF4661" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
      <linearGradient
        id="assemble_hyper_gradient1"
        x1="64.2531"
        y1="32.4099"
        x2="65.5331"
        y2="59.2905"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1635" stopColor="#F0E2DB" />
        <stop offset="0.3713" stopColor="#EFADA8" />
        <stop offset="0.5367" stopColor="#EF8989" />
        <stop offset="0.6181" stopColor="#EF7B7F" />
        <stop offset="0.6351" stopColor="#EF787C" />
        <stop offset="0.7894" stopColor="#EE5B6B" />
        <stop offset="0.9165" stopColor="#EF4661" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
    </defs>
  </svg>
);
