export const ConnectCloudIcon = ({ height = 53, style }) => (
  <svg
    width={Math.abs(height * 1.19)}
    height={height}
    viewBox="0 0 83 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M27.1 54.2C42.0669 54.2 54.2 42.0669 54.2 27.1C54.2 12.1331 42.0669 0 27.1 0C12.1331 0 0 12.1331 0 27.1C0 42.0669 12.1331 54.2 27.1 54.2Z"
      fill="url(#cccloiud_gradient)"
    />
    <path
      d="M56.7 49.0999C69.513 49.0999 79.9 38.713 79.9 25.9C79.9 13.0869 69.513 2.69995 56.7 2.69995C43.887 2.69995 33.5 13.0869 33.5 25.9C33.5 38.713 43.887 49.0999 56.7 49.0999Z"
      stroke="#18244D"
      strokeWidth="5"
      strokeMiterlimit="10"
    />
    <path
      d="M56.7 37.7001C63.217 37.7001 68.5 32.4171 68.5 25.9001C68.5 19.3831 63.217 14.1001 56.7 14.1001C50.1831 14.1001 44.9 19.3831 44.9 25.9001C44.9 32.4171 50.1831 37.7001 56.7 37.7001Z"
      stroke="#18244D"
      strokeWidth="5"
      strokeMiterlimit="10"
    />
    <defs>
      <linearGradient
        id="cccloiud_gradient"
        x1="27.1418"
        y1="55.2411"
        x2="27.1418"
        y2="-2.9309"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.1635" stopColor="#F0E2DB" />
        <stop offset="0.2626" stopColor="#F0C8C1" />
        <stop offset="0.4561" stopColor="#EF9B98" />
        <stop offset="0.6336" stopColor="#EF787D" />
        <stop offset="0.789" stopColor="#EE5B6B" />
        <stop offset="0.9164" stopColor="#EF4661" />
        <stop offset="1" stopColor="#EF3D5D" />
      </linearGradient>
    </defs>
  </svg>
);
